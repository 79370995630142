exports.components = {
  "component---src-app-templates-build-campaign-tsx": () => import("./../../../src/app/templates/build/Campaign.tsx" /* webpackChunkName: "component---src-app-templates-build-campaign-tsx" */),
  "component---src-app-templates-build-event-tsx": () => import("./../../../src/app/templates/build/Event.tsx" /* webpackChunkName: "component---src-app-templates-build-event-tsx" */),
  "component---src-app-templates-build-find-a-village-tsx": () => import("./../../../src/app/templates/build/FindAVillage.tsx" /* webpackChunkName: "component---src-app-templates-build-find-a-village-tsx" */),
  "component---src-app-templates-build-investor-tsx": () => import("./../../../src/app/templates/build/Investor.tsx" /* webpackChunkName: "component---src-app-templates-build-investor-tsx" */),
  "component---src-app-templates-build-news-article-tsx": () => import("./../../../src/app/templates/build/NewsArticle.tsx" /* webpackChunkName: "component---src-app-templates-build-news-article-tsx" */),
  "component---src-app-templates-build-region-tsx": () => import("./../../../src/app/templates/build/Region.tsx" /* webpackChunkName: "component---src-app-templates-build-region-tsx" */),
  "component---src-app-templates-build-standard-page-tsx": () => import("./../../../src/app/templates/build/StandardPage.tsx" /* webpackChunkName: "component---src-app-templates-build-standard-page-tsx" */),
  "component---src-app-templates-build-village-tsx": () => import("./../../../src/app/templates/build/Village.tsx" /* webpackChunkName: "component---src-app-templates-build-village-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ask-a-question-tsx": () => import("./../../../src/pages/AskAQuestion.tsx" /* webpackChunkName: "component---src-pages-ask-a-question-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/Events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-information-pack-tsx": () => import("./../../../src/pages/InformationPack.tsx" /* webpackChunkName: "component---src-pages-information-pack-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/News.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/Preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-search-form-tsx": () => import("./../../../src/pages/SearchForm.tsx" /* webpackChunkName: "component---src-pages-search-form-tsx" */)
}

